import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, createVNode as _createVNode, unref as _unref } from "vue"

const _hoisted_1 = ["aria-label"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "p-grid" }
const _hoisted_4 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_5 = { for: "specialists" }
const _hoisted_6 = { class: "dropdown-item" }
const _hoisted_7 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_8 = { for: "specialist" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { class: "dropdown-item" }
const _hoisted_12 = {
  key: 0,
  class: "p-invalid"
}

import { PatientDto } from '@/models/Patient';
import { UserRole, UserTableDto } from '@/models/User';
import { patientService } from '@/services/PatientService';
import { userService } from '@/services/UserService';
import { ServiceError } from '@/services/util/ServiceError';
import MultiSelect from 'primevue/multiselect';
import { useToast } from 'primevue/usetoast';
import { computed, onMounted, Ref, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import CardComponent from '../CardComponent.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'AssociatedSpecialists',
  setup(__props) {

const toast = useToast();
const { t } = useI18n();
const route = useRoute();
const patientId = Array.isArray(route.params.patientId) ? route.params.patientId[0] : route.params.patientId;

const editMode = ref(false);
const waitSubmit = ref(false);
const showValidation = ref(false);

const showSelectSpecialistDialog = ref(false);
const showConfirmDialog = ref(false);

const patientInitialDefault = ref<PatientDto>();
const patient: Ref<PatientDto | undefined> = ref();

const ownerSpecialistIds = ref<string[]>();
const specialistOptions: Ref<UserTableDto[] | undefined> = ref();
const specialistOptionsToReassign: Ref<UserTableDto[] | undefined> = computed(() =>
  specialistOptions.value?.filter((s) => ownerSpecialistIds.value?.indexOf(s.professionalId) !== -1),
);

const specialistIdToReassign = ref();

onMounted(async () => {
  await loadPatientData();
  await fillSpecialistDropdown();
});

async function loadPatientData() {
  const result = await patientService.find(patientId);
  if (!(result instanceof ServiceError)) {
    patient.value = result;
    patientInitialDefault.value = { ...result };

    ownerSpecialistIds.value = !patient.value.specialists
      ? []
      : patient.value.specialists
          .filter((s) => {
            return s.permission === 'OWNER';
          })
          .map((s) => s.professionalId);
  }
}

const onSave = async () => {
  if (patient.value && ownerSpecialistIds.value) {
    if (ownerSpecialistIds.value.length == 1) {
      showConfirmDialog.value = true;
    } else {
      showSelectSpecialistDialog.value = true;
    }
  }
};

const onSubmit = async () => {
  if (patient.value && ownerSpecialistIds.value) {
    try {
      waitSubmit.value = true;
      await patientService.modifySpecialists(patientId, ownerSpecialistIds.value, specialistIdToReassign.value);
      toast.add({ severity: 'success', summary: `${t('messages.notifications.successEditPatient')}`, life: 3000 });
    } catch (error) {
      toast.add({
        severity: 'error',
        summary: `${t('messages.notifications.errorEditPatient')} ${t('messages.notifications.tryLater')}`,
        life: 3000,
      });
    } finally {
      waitSubmit.value = false;
      editMode.value = false;
      showConfirmDialog.value = false;
      showSelectSpecialistDialog.value = false;
    }
  }
  // }
};

const onCancelEdition = async () => {
  editMode.value = false;
  setInitialVaules();
};

const setInitialVaules = () => {
  if (patientInitialDefault.value) {
    ownerSpecialistIds.value = [
      ...(!patientInitialDefault.value.specialists
        ? []
        : patientInitialDefault.value.specialists
            .filter((s) => {
              return s.permission === 'OWNER';
            })
            .map((s) => s.professionalId)),
    ];
  }
};

async function fillSpecialistDropdown() {
  const result = await userService.findAll([UserRole.SPECIALIST]);

  if (!(result instanceof ServiceError)) {
    specialistOptions.value = result;
  }
}
const onCancelModal = async () => {
  showConfirmDialog.value = false;
  showSelectSpecialistDialog.value = false;
};

const onConfirmSelect = async () => {
  showValidation.value = true;
  if (specialistIdToReassign.value) {
    showSelectSpecialistDialog.value = false;
    showConfirmDialog.value = true;
  }
};

return (_ctx: any,_cache: any) => {
  const _component_Button = _resolveComponent("Button")!
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(CardComponent, {
      cardTitle: _ctx.$t('configuration.associatedSpecialists'),
      "edit-mode": editMode.value
    }, {
      headingActions: _withCtx(() => [
        _withDirectives((_openBlock(), _createBlock(_component_Button, {
          class: _normalizeClass(["p-button-rounded p-button-only-icon p-button-only-icon--dark", editMode.value ? 'p-disabled' : '']),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (editMode.value = !editMode.value))
        }, {
          default: _withCtx(() => [
            _createElementVNode("i", {
              class: "icon-edit",
              "aria-label": _ctx.$t('common.edit'),
              "aria-hidden": "true"
            }, null, 8, _hoisted_1)
          ]),
          _: 1
        }, 8, ["class"])), [
          [
            _directive_tooltip,
            _ctx.$t('common.edit'),
            void 0,
            { bottom: true }
          ]
        ])
      ]),
      cardContent: _withCtx(() => [
        (patient.value)
          ? (_openBlock(), _createElementBlock("form", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t('patient.specialists-short')) + "*", 1),
                  _createVNode(_unref(MultiSelect), {
                    id: "specialists",
                    modelValue: ownerSpecialistIds.value,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((ownerSpecialistIds).value = $event)),
                    options: specialistOptions.value,
                    optionValue: "professionalId",
                    placeholder: _ctx.$t('medicalTest.select-specialist'),
                    class: _normalizeClass(["p-column-filter multiselect-custom", [
                'multiselect-custom',
                {
                  // 'p-invalid': !validatedForm.roles.valid,
                  'no-editable': !editMode.value,
                  // 'p-disabled': !editMode || (editMode && !isAdminUser),
                },
              ]]),
                    editMode: editMode.value
                  }, {
                    value: _withCtx((slotProps) => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(slotProps.value, (option, index) => {
                        return (_openBlock(), _createElementBlock("span", {
                          key: option.code,
                          class: "specialist-item-value"
                        }, _toDisplayString(specialistOptions.value?.find((specialist) => specialist.professionalId == option)?.name) + " " + _toDisplayString(specialistOptions.value?.find((specialist) => specialist.professionalId == option)?.surname) + " " + _toDisplayString(index !== slotProps.value.length - 1 ? ', ' : ''), 1))
                      }), 128)),
                      (!slotProps.value || slotProps.value.length === 0)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            _createTextVNode(_toDisplayString(slotProps.placeholder), 1)
                          ], 64))
                        : _createCommentVNode("", true)
                    ]),
                    option: _withCtx((slotProps) => [
                      _createVNode(_component_Avatar, {
                        image: slotProps.option.picture,
                        class: "p-mr-2",
                        size: "small",
                        shape: "circle"
                      }, null, 8, ["image"]),
                      _createElementVNode("span", _hoisted_6, _toDisplayString(slotProps.option.name) + " " + _toDisplayString(slotProps.option.surname), 1)
                    ]),
                    _: 1
                  }, 8, ["modelValue", "options", "placeholder", "editMode", "class"])
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      footerActions: _withCtx(() => [
        _createVNode(_component_Button, {
          class: "p-button-secondary p-button-medium",
          label: _ctx.$t('common.cancel'),
          onClick: onCancelEdition
        }, null, 8, ["label"]),
        _createVNode(_component_Button, {
          class: "p-button p-button-info p-button-medium",
          loading: waitSubmit.value,
          label: _ctx.$t('common.save'),
          onClick: onSave
        }, null, 8, ["loading", "label"])
      ]),
      _: 1
    }, 8, ["cardTitle", "edit-mode"]),
    _createVNode(_component_Dialog, {
      visible: showSelectSpecialistDialog.value,
      "onUpdate:visible": _cache[5] || (_cache[5] = ($event: any) => ((showSelectSpecialistDialog).value = $event)),
      header: _ctx.$t('configuration.selectSpecialistModalTitle'),
      modal: true,
      class: "p-fluid"
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: _ctx.$t('common.cancel'),
          class: "p-button p-component p-button-secondary p-button-medium",
          disabled: waitSubmit.value,
          onClick: _cache[3] || (_cache[3] = ($event: any) => (onCancelModal()))
        }, null, 8, ["label", "disabled"]),
        _createVNode(_component_Button, {
          label: _ctx.$t('common.select'),
          class: "p-button p-component p-button-primary p-button-medium",
          disabled: waitSubmit.value,
          onClick: _cache[4] || (_cache[4] = ($event: any) => (onConfirmSelect()))
        }, null, 8, ["label", "disabled"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('configuration.selectSpecialistModalMessage')), 1),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t('configuration.principal-specialist')) + "*", 1),
          _createVNode(_component_Dropdown, {
            id: "specialist",
            modelValue: specialistIdToReassign.value,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((specialistIdToReassign).value = $event)),
            required: "true",
            options: specialistOptionsToReassign.value,
            optionValue: "professionalId",
            placeholder: _ctx.$t('configuration.principal-specialist'),
            class: _normalizeClass({ 'p-invalid': showValidation.value && !specialistIdToReassign.value })
          }, {
            value: _withCtx((slotProps) => [
              (slotProps.value)
                ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(specialistOptionsToReassign.value?.find((specialist) => specialist.professionalId == slotProps.value)?.name) + " " + _toDisplayString(specialistOptionsToReassign.value?.find((specialist) => specialist.professionalId == slotProps.value)?.surname), 1))
                : (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(slotProps.placeholder), 1))
            ]),
            option: _withCtx((slotProps) => [
              _createVNode(_component_Avatar, {
                image: slotProps.option.picture,
                class: "p-mr-2",
                size: "small",
                shape: "circle"
              }, null, 8, ["image"]),
              _createElementVNode("span", _hoisted_11, _toDisplayString(slotProps.option.name) + " " + _toDisplayString(slotProps.option.surname), 1)
            ]),
            _: 1
          }, 8, ["modelValue", "options", "placeholder", "class"]),
          (showValidation.value && !specialistIdToReassign.value)
            ? (_openBlock(), _createElementBlock("small", _hoisted_12, _toDisplayString(_ctx.$t(`configuration.editSpecialistModalInvalid`)), 1))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["visible", "header"]),
    _createVNode(_component_Dialog, {
      visible: showConfirmDialog.value,
      "onUpdate:visible": _cache[8] || (_cache[8] = ($event: any) => ((showConfirmDialog).value = $event)),
      header: _ctx.$t('configuration.editSpecialistModalTitle'),
      modal: true,
      class: "p-fluid"
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: _ctx.$t('common.cancel'),
          class: "p-button p-component p-button-secondary p-button-medium",
          disabled: waitSubmit.value,
          onClick: _cache[6] || (_cache[6] = ($event: any) => (onCancelModal()))
        }, null, 8, ["label", "disabled"]),
        _createVNode(_component_Button, {
          label: _ctx.$t('common.confirm'),
          class: "p-button p-component p-button-primary p-button-medium",
          disabled: waitSubmit.value,
          onClick: _cache[7] || (_cache[7] = ($event: any) => (onSubmit()))
        }, null, 8, ["label", "disabled"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('configuration.editSpecialistModalMessage')), 1)
      ]),
      _: 1
    }, 8, ["visible", "header"])
  ], 64))
}
}

})